<template>
    <div>
        <h6 class="mb-2">{{$t('student_list_with_photo')}}</h6>
        <div class="col-12 border rounded-sm p-4 mb-4">
            <div class="row">
                <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                    <b-form-group :label="$t('academic_year')">
                        {{students[0] ? students[0].academic_year : '-'}}
                    </b-form-group>
                </div>
                <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                    <b-form-group :label="$t('period')">
                        {{students[0] ? students[0].semester : '-'}}
                    </b-form-group>
                </div>
                <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                    <b-form-group :label="$t('course')">
                        {{coverLetter.course_code+' - '+coverLetter.course_name}}
                    </b-form-group>
                </div>
                <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                    <b-form-group :label="$t('section')">
                        {{sectionNo}}
                    </b-form-group>
                </div>
            </div>

        </div>
        <b-card-group deck>
            <div v-for="(item, key) in students" class="col-12 col-sm-6 col-md-6 col-lg-4 mb-4" :key="key">
                <b-card
                    :title="item.student_number"
                    :img-src="item.photo != null ? item.photo : require('@/assets/img/no-photo.jpg')"
                    :img-alt="item.name+' '+item.surname"
                    img-top
                    tag="article"
                    style="max-width: 20rem;"
                    class="mb-2">
                    <b-card-text>
                        {{item.name+' '+item.surname}}
                    </b-card-text>
                </b-card>
            </div>

        </b-card-group>
        <div class="col-12 col-sm-12 col-md-12 col-lg-12">
            <b-button variant="primary" @click="goBack">{{$t('back')}}</b-button>
        </div>
    </div>
</template>
<script>
import StudentService from "@/services/StudentService";
import qs from "qs";

export default {
    components: {
    },
    props: {
        courseId: {
            default: null,
            type: Number
        },
        semesterId: {
            default: '0',
            type: String
        },
        sectionNo: {
            default: 0,
            type: Number
        },
        coverLetter: {
            default: null,
            type: Object
        }
    },
    data() {
        return {
            students: [],
        }
    },
    metaInfo() {
        return {
            title: this.$t("instructor_courses_title")
        }
    },
    methods: {

        getData() {
            let data = {semester_id:this.semesterId, course_id:this.courseId, section:this.sectionNo}

            let config={
                params:data,
                paramsSerializer:(params)=>qs.stringify(params,{encode:false})
            }
            StudentService.getInstructorStudents(config).then(response => {
                response.data.data.forEach(item => {
                    this.students.push({
                        student_number: item.student_number,
                        name: item.name,
                        surname: item.surname,
                        photo: item.photo,
                        semester: item.semester_name,
                        academic_year: item.academic_year
                    })
                })

            })
        },

        goBack() {
            this.$emit('back')
        }
    },

    created() {
        this.getData()
    },

    watch: {
        courseId: {
            handler: function (val) {
                this.getData(val)
            }
        },
        semesterId: {
            handler: function (val) {
                this.getData(val)
            }
        },
        sectionNo: {
            handler: function (val) {
                this.getData(val)
            }
        }
    }
};
</script>
